// Generated by Framer (e42f2cb)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
const MaterialFonts = getFonts(Material);
const MaterialControls = getPropertyControls(Material);

const cycleOrder = ["BxXM5QMgd", "YNtJZhzd8", "YnnEQZzze", "Id029X4dl", "bdD4Ua2QW", "P_e228SLO"];

const serializationHash = "framer-gZSFD"

const variantClassNames = {bdD4Ua2QW: "framer-v-1y50vzc", BxXM5QMgd: "framer-v-12adbhg", Id029X4dl: "framer-v-1qws6kw", P_e228SLO: "framer-v-6u2qij", YnnEQZzze: "framer-v-zjz7x9", YNtJZhzd8: "framer-v-45f6gm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {"2XL": "P_e228SLO", LG: "Id029X4dl", MD: "YnnEQZzze", SM: "YNtJZhzd8", XL: "bdD4Ua2QW", XS: "BxXM5QMgd"}

const getProps = ({color, height, id, name1, style1, width, ...props}) => { return {...props, ihqgEoX72: name1 ?? props.ihqgEoX72 ?? "AccessAlarms", jcOpkO121: color ?? props.jcOpkO121 ?? "var(--token-76222ffd-05e5-4f16-bbbb-215ca1a3fab9, rgb(255, 255, 255))", Ul7JVyvFX: style1 ?? props.Ul7JVyvFX ?? "Rounded", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BxXM5QMgd"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;name1?: string;color?: string;style1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ihqgEoX72, jcOpkO121, Ul7JVyvFX, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BxXM5QMgd", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12adbhg", className, classNames)} data-framer-name={"XS"} layoutDependency={layoutDependency} layoutId={"BxXM5QMgd"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({bdD4Ua2QW: {"data-framer-name": "XL"}, Id029X4dl: {"data-framer-name": "LG"}, P_e228SLO: {"data-framer-name": "2XL"}, YnnEQZzze: {"data-framer-name": "MD"}, YNtJZhzd8: {"data-framer-name": "SM"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-68d9v5-container"} data-framer-name={"Icon -Google Material"} layoutDependency={layoutDependency} layoutId={"VSsNpRXb2-container"} name={"Icon -Google Material"}><Material color={jcOpkO121} height={"100%"} iconSearch={"Home"} iconSelection={ihqgEoX72} iconStyle15={Ul7JVyvFX} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"VSsNpRXb2"} layoutId={"VSsNpRXb2"} mirrored={false} name={"Icon -Google Material"} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gZSFD.framer-nzn3r5, .framer-gZSFD .framer-nzn3r5 { display: block; }", ".framer-gZSFD.framer-12adbhg { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; padding: 0px; position: relative; width: min-content; }", ".framer-gZSFD .framer-68d9v5-container { flex: none; height: 16px; position: relative; width: 16px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gZSFD.framer-12adbhg { gap: 0px; } .framer-gZSFD.framer-12adbhg > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-gZSFD.framer-12adbhg > :first-child { margin-left: 0px; } .framer-gZSFD.framer-12adbhg > :last-child { margin-right: 0px; } }", ".framer-gZSFD.framer-v-45f6gm .framer-68d9v5-container { height: 24px; width: 24px; }", ".framer-gZSFD.framer-v-zjz7x9 .framer-68d9v5-container { height: 32px; width: 32px; }", ".framer-gZSFD.framer-v-1qws6kw .framer-68d9v5-container { height: 48px; width: 48px; }", ".framer-gZSFD.framer-v-1y50vzc .framer-68d9v5-container { height: 64px; width: 64px; }", ".framer-gZSFD.framer-v-6u2qij .framer-68d9v5-container { height: 96px; width: 96px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 16
 * @framerIntrinsicWidth 16
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"YNtJZhzd8":{"layout":["auto","auto"]},"YnnEQZzze":{"layout":["auto","auto"]},"Id029X4dl":{"layout":["auto","auto"]},"bdD4Ua2QW":{"layout":["auto","auto"]},"P_e228SLO":{"layout":["auto","auto"]}}}
 * @framerVariables {"ihqgEoX72":"name1","jcOpkO121":"color","Ul7JVyvFX":"style1"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerRL9pcv4O2: React.ComponentType<Props> = withCSS(Component, css, "framer-gZSFD") as typeof Component;
export default FramerRL9pcv4O2;

FramerRL9pcv4O2.displayName = "Elements / Icon";

FramerRL9pcv4O2.defaultProps = {height: 16, width: 16};

addPropertyControls(FramerRL9pcv4O2, {variant: {options: ["BxXM5QMgd", "YNtJZhzd8", "YnnEQZzze", "Id029X4dl", "bdD4Ua2QW", "P_e228SLO"], optionTitles: ["XS", "SM", "MD", "LG", "XL", "2XL"], title: "Variant", type: ControlType.Enum}, ihqgEoX72: MaterialControls?.["iconSelection"] && {...MaterialControls["iconSelection"], defaultValue: "AccessAlarms", description: undefined, hidden: undefined, title: "Name"}, jcOpkO121: {defaultValue: "var(--token-76222ffd-05e5-4f16-bbbb-215ca1a3fab9, rgb(255, 255, 255)) /* {\"name\":\"Default\"} */", title: "Color", type: ControlType.Color}, Ul7JVyvFX: MaterialControls?.["iconStyle15"] && {...MaterialControls["iconStyle15"], defaultValue: "Rounded", description: undefined, hidden: undefined, title: "Style"}} as any)

addFonts(FramerRL9pcv4O2, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})